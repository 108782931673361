@keyframes zoomAnimation {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.animatedBackground {
  background-image: url('../../../public/assets/images/Team.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: brightness(50%);
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  animation: zoomAnimation 20s ease infinite;
}

.animatedBackground::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  z-index: -1;
  transform: scale(1);
  animation: zoomAnimation 20s ease infinite;
}
